import { get } from 'vuex-pathify'

export default () => {
	return {
		data() {
			return {
				firstname: null,
				lastname: null,
				email: null,
				origin: null,
				place: null,
				loading: false,
				state: 'pending',
				validation: {},
			}
		},
		computed: {
			user: get('shop/user'),
		},
		methods: {
			async submit() {
				if (this.loading) return
				if (this.email && this.email == this.user?.accountEmail) {
					if (!this.firstname) this.firstname = this.user.firstname
					if (!this.lastname) this.lastname = this.user.lastname
				}

				return await this.$shopApi.post({
					url: '/user-forms/newsletter',
					data: {
						data: { firstname: this.firstname, lastname: this.lastname, email: this.email },
						origin: this.origin,
						place: this.place,
					},
					loading: (v) => (this.loading = v),
					onSuccess: () => {
						this.state = 'success'
					},
					onError: () => {
						this.state = 'error'
					},
					onValidation: ({ validation }) => (this.validation = validation),
				})
			},
		},
	}
}
