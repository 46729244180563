<script>
export default {
	props: {
		data: Object,
	},
	computed: {
		alignment() {
			if (this.$b.td) {
				switch (this.data.textAlignDesktop) {
					case 'right':
						return 'justify-end text-end'

					case 'center':
						return 'justify-center text-center'

					default:
						return 'justify-start text-start'
				}
			} else {
				switch (this.data.textAlignMobile) {
					case 'right':
						return 'justify-end text-end'

					case 'center':
						return 'justify-center text-center'

					default:
						return 'justify-start text-start'
				}
			}
		},
		hasData() {
			return this.data.copyrightText || this.data.certificates
		},
	},
	cssVars: {
		selector: '.footer',
	},
}
</script>
<template>
	<div class="footer primary base w100" v-if="hasData">
		<v-container>
			<v-row align="center" :class="alignment">
				<v-col
					cols="12"
					:md="data.certificates ? 8 : 12"
					class="primary"
					:class="[{ 'px-1': $b.d }, alignment]"
				>
					<div v-html="data.copyrightText"></div>
				</v-col>

				<v-col
					v-if="data.certificates"
					cols="12"
					md="4"
					class="d-flex"
					:class="[{ 'px-1': $b.d }, alignment]"
				>
					<Footer-Certificates :items="data.certificates" />
				</v-col>
			</v-row>
		</v-container>
	</div>
</template>
