<template>
	<SafeLink class="footer d-flex base" :to="link">
		<v-icon :color="$vars.basetext" class="mr-2">{{ icon }}</v-icon>
		<div class="base">{{ text }}</div>
	</SafeLink>
</template>

<script>
export default {
	name: 'comp_FooterLinkItem',
	props: {
		text: {
			type: String,
			required: true,
		},
		link: {
			type: String,
			default: '',
		},
		icon: String,
	},
	cssVars: {
		selector: '.footer',
	},
}
</script>

<style scoped>
.footer {
	margin-bottom: 15px;
}
</style>
