<template>
	<div :class="{ w250: $b.m }">
		<FooterLinkItem v-for="(i, n) in items" :key="n" v-bind="i" />
	</div>
</template>

<script>
export default {
	name: 'comp_FooterLinkList',
	props: {
		items: Array,
	},
}
</script>

<style scoped>
.w250 {
	width: 250px;
}
</style>
