<script>
export default {
	props: {
		data: Object,
		textAlign: String,
	},
	data() {
		return { show: false }
	},
	computed: {
		hasCategories() {
			return this.data.categories.length > 0 && this.hasNonNullCategories
		},
		hasNonNullCategories() {
			return this.data.categories.some((c) => c !== null)
		},
	},

	cssVars: {
		selector: '.footer',
	},
}
</script>

<template>
	<div :class="[{ 'mb-6': $b.m }, textAlign]" v-if="hasCategories">
		<div v-if="$b.m && data.enableDropdown">
			<div class="footer-link header mb-3" @click="show = !show">
				<span>
					{{ data.title }}
					<v-icon :color="$vars.headertext">{{ show ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
				</span>
			</div>

			<div class="d-flex flex-column" :class="textAlign" v-if="show">
				<SafeLink
					v-for="(category, index) of data.categories.slice(0, 9)"
					:key="`categories_${index}`"
					class="footer-link text-capitalize link"
					style="cursor: pointer"
					:to="$shop.getShopRoute({ categories: category.familyUrlNames })"
					:id="category.name"
					v-evt-click
				>
					<span>{{ category.name }}</span>
				</SafeLink>
				<SafeLink :to="$shop.getShopRoute()" v-if="data.categories.length > 9" class="link">
					Ver todas...
				</SafeLink>
			</div>
		</div>
		<div v-else>
			<div class="footer-link header mb-3" v-if="data.title">
				<span>{{ data.title }}</span>
			</div>

			<div class="d-flex flex-column" :class="textAlign">
				<SafeLink
					v-for="(category, index) of data.categories.slice(0, 9)"
					:key="`categories_${index}`"
					class="footer-link text-capitalize link"
					style="cursor: pointer"
					:to="$shop.getShopRoute({ categories: category.familyUrlNames })"
					:id="category.name"
					v-evt-click
				>
					<span>{{ category.name }}</span>
				</SafeLink>
				<SafeLink :to="$shop.getShopRoute()" v-if="data.categories.length > 9" class="link">
					Ver todas...
				</SafeLink>
			</div>
		</div>
	</div>
</template>

<style scoped>
.footer-link {
	margin-bottom: 10px;
}
</style>
