<script>
import newsletterFormMixin from '@/v-shop/mixins/newsletter-form-mixin'
export default {
	props: {
		title: String,
		alignment: String,
	},
	mixins: [newsletterFormMixin()],
	cssVars: {
		selector: '.footer',
	},
	watch: {
		user() {
			if (this.user) {
				this.email = this.user.accountEmail
			}
		},
	},
	mounted() {
		this.place = 'footer'
		if (this.user) {
			this.email = this.user.accountEmail
		}
	},
}
</script>

<template>
	<Validator v-bind="{ validation }">
		<div class="d-flex flex-column" :class="[$bem('newsletter-form'), { 'mx-2': $b.mt }]">
			<h1 :class="`${alignment} footer-link header mb-3`" v-if="title">
				{{ title }}
			</h1>
			<form @submit.prevent="submit()" v-if="state != 'success'">
				<div>
					<TextField v-model="email" label="Correo electrónico" solo flat />
				</div>
				<div>
					<Button
						type="submit"
						:loading="loading"
						class="rounded-xl font-weight-thin my-4 w100"
						color="cta"
						:class="[$bem('__btn')]"
					>
						Enviar
					</Button>
				</div>
			</form>
			<div class="text-center font-weight-bold w100" v-if="state == 'success'">
				Gracias por registrarte a nuestro newsletter
			</div>
		</div>
	</Validator>
</template>

<style lang="scss" scoped>
.newsletter-form ::v-deep .v-input__slot {
	background: var(--secondary);
}
.newsletter-form ::v-deep .v-messages__message {
	display: inline-block;
	background: var(--error);
	padding: 3px;
	border-radius: 4px;
	color: white;
}
.newsletter-form ::v-deep .v-text-field__details {
	padding-left: 0;
}
</style>
