<template>
	<!--TODO: add list item slot-->
	<v-col v-bind="$attrs" class="footer">
		<div class="footer-link header">{{ title }}</div>
		<slot>
			<div class="footer-link cursor-pointer" v-for="(item, index) of items" :key="index">
				<SafeLink :to="item.link" class="link">
					{{ item.text }}
				</SafeLink>
			</div>
		</slot>
	</v-col>
</template>

<script>
export default {
	name: 'comp_FooterLinkList',
	props: {
		title: String,
		items: Array,
	},
}
</script>

<style scoped>
.footer-link {
	margin-bottom: 15px;
}
</style>
