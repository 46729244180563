<script>
export default {
	props: {
		data: Object,
		textAlign: String,
	},
	data() {
		return {
			show: false,
		}
	},
	computed: {
		hasBrands() {
			return this.data.brands.length > 0 && this.hasNonNullBrands
		},
		hasNonNullBrands() {
			return this.data.brands.some((c) => c !== null)
		},
	},
	cssVars: {
		selector: '.footer',
	},
}
</script>

<template>
	<div :class="[{ 'mb-6': $b.m }, textAlign]" v-if="hasBrands">
		<div v-if="$b.m && data.enableDropdown">
			<div class="footer-link header mb-3" v-if="data.title" @click="show = !show">
				<span>
					{{ data.title }}
					<v-icon :color="$vars.headertext">{{ show ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
				</span>
			</div>

			<div :class="show ? 'item--active' : 'item'" class="transition">
				<SafeLink
					v-for="(brand, index) of data.brands.slice(0, 9)"
					:key="`brands_${index}`"
					class="footer-link text-capitalize link"
					style="cursor: pointer"
					:to="$shop.getShopRoute({ brand: brand.urlName })"
					:id="brand.name"
					v-evt-click
				>
					<span>{{ brand.name }}</span>
				</SafeLink>
				<SafeLink :to="$shop.getShopRoute()" v-if="data.brands.length > 9" class="link">
					Ver todas...
				</SafeLink>
			</div>
		</div>
		<div v-else>
			<div class="footer-link header mb-3" v-if="data.title">
				<span>{{ data.title }}</span>
			</div>

			<div class="d-flex flex-column" :class="textAlign" v-show="false">
				<SafeLink
					v-for="(brand, index) of data.brands.slice(0, 9)"
					:key="`brands_${index}`"
					class="footer-link text-capitalize link"
					style="cursor: pointer"
					:to="$shop.getShopRoute({ brand: brand.urlName })"
					:id="brand.name"
					v-evt-click
				>
					<span>{{ brand.name }}</span>
				</SafeLink>
				<SafeLink :to="$shop.getShopRoute()" v-if="data.brands.length > 9" class="link">
					Ver todas...
				</SafeLink>
			</div>
		</div>
	</div>
</template>

<style scoped lang="scss">
.footer-link {
	margin-bottom: 10px;
}
.transition {
	transition: min-height 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}
.item {
	display: none;
	min-height: 32px;

	&--active {
		display: flex;
		flex-direction: column;
		min-height: 64px;
	}
}
</style>
