<script>
export default {
	props: {
		data: Object,
		textAlign: String,
	},
	data() {
		return { show: false }
	},
	cssVars: {
		selector: '.footer',
	},
}
</script>

<template>
	<div :class="[{ 'mb-6': $b.m }, textAlign]">
		<div v-if="$b.m && data.enableDropdown">
			<div class="footer-link header mb-3" v-if="data.title" @click="show = !show">
				<span>
					{{ data.title }}
					<v-icon :color="$vars.headertext">{{ show ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
				</span>
			</div>

			<div v-if="show">
				<div
					v-for="(el, index) of data.linkList"
					:key="index"
					class="footer-link d-flex base"
					:class="textAlign"
				>
					<span>
						<v-icon v-if="el.icon" class="mr-2" :color="$vars.basetext">{{ el.icon }}</v-icon>
						<SafeLink :to="el.link" class="link">
							{{ el.text }}
						</SafeLink>
					</span>
				</div>
			</div>
		</div>
		<div v-else>
			<div class="footer-link header mb-3" v-if="data.title">
				<span>{{ data.title }}</span>
			</div>

			<div
				v-for="(el, index) of data.linkList"
				:key="index"
				class="footer-link d-flex base"
				:class="textAlign"
			>
				<v-icon v-if="el.icon" class="mr-2" :color="$vars.basetext">{{ el.icon }}</v-icon>
				<SafeLink :to="el.link" class="link">
					<span>{{ el.text }}</span>
				</SafeLink>
			</div>
		</div>
	</div>
</template>

<style scoped>
.footer-link {
	margin-bottom: 10px;
}
</style>
