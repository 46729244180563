<script>
export default {
	name: 'comp_FooterSocialItem',
	props: {
		link: String,
		icon: String,
	},
}
</script>

<template>
	<SafeLink class="d-flex footer base" :to="link">
		<v-icon :color="$vars.basetext" class="mr-2">{{ icon }}</v-icon>
	</SafeLink>
</template>

<style scoped>
.footer {
	margin-bottom: 15px;
}
</style>
