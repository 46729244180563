var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.mappedColumns)?_c('div',{staticClass:"footer base"},[_c('v-container',[_c('div',{staticClass:"d-flex",class:[
				{
					'flex-wrap': _vm.$b.mt,
				},
				_vm.alignment ]},_vm._l((_vm.mappedColumns),function(item,i){return _c('div',{key:i,class:_vm.$b({
						t: item.colType == 'info' || item.colType == 'form' ? 'w100' : 'w25 pa-2 mb-3',
						d: item.colType == 'info' || item.colType == 'form' ? 'w150 pa-2' : 'w100 pa-2',
						m: 'w100',
					})},[(item.colType == 'info')?_c('Footer-BusinessInfo',{attrs:{"businessInfo":item.businessInfo,"textAlignDesktop":_vm.data.textAlignDesktop,"textAlignMobile":_vm.data.textAlignMobile}}):(item.colType == 'link')?_c('Footer-LinkList',{attrs:{"data":item,"textAlign":_vm.alignment}}):(item.colType == 'cat')?_c('Footer-CategoriesList',{attrs:{"data":item,"textAlign":_vm.alignment}}):(item.colType == 'brand')?_c('Footer-BrandsList',{attrs:{"data":item,"textAlign":_vm.alignment}}):_c('div',{class:{ 'mb-4': _vm.$b.m }},[_c('Footer-Newsletter',{attrs:{"title":item.title,"alignment":_vm.alignment}})],1)],1)}),0),_c('hook',{attrs:{"zone":"end"}})],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }