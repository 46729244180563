<template>
	<div>
		<FooterSocialItem v-for="(i, n) in items" :key="n" v-bind="i" />
	</div>
</template>

<script>
export default {
	name: 'comp_FooterSocialsList',
	props: {
		items: Array,
	},
}
</script>

<style scoped></style>
